export enum EBreadCrumd {
  HOMEAPGE = '',

  //Quản lý tài khoản
  ACCOUNT = '/account',

  ACCOUNT_CUSTOMER = '/account/customer',
  ACCOUNT_CUSTOMER_DETAIL = '/account/customer/detail',
  ACCOUNT_CUSTOMER_BILL = '/account/customer/bill',

  ACCOUNT_ADMIN = '/account/admin',
  ACCOUNT_GROUP_ADMIN = '/account/group-admin',
  ACCOUNT_RANK = '/account-rank',
  ACCOUNT_RANK_LIST = '/account/account-rank',
  //Quản lý đơn hàng
  ORDER_MANAGER = '/order-manager',
  ORDER_MANAGER_LIST = '/order-manager/list',
  ORDER_MANAGER_ADD = '/order-manager/add-and-delivery',
  ORDER_MANAGER_DETAIL = '/order-manager/detail',
  ORDER_MANAGER_EDIT = '/order-manager/edit',

  CUSTOMER_RETURN_PRODUCT = '/order-manager/customer-return-product',
  CUSTOMER_RETURN_PRODUCT_ADD = '/order-manager/customer-return-product/add',
  CUSTOMER_RETURN_PRODUCT_DETAIL = '/order-manager/customer-return-product/detail',
  //Quản lý sản phẩm
  PRODUCT = '/product-manager/product',
  PRODUCT_ADD = '/product-manager/product/add',
  PRODUCT_DETAIL = '/product-manager/product/edit',

  PRODUCT_DETAIL_EDIT = '/product-manager/product/detail/edit',
  PRODUCT_COPPY_ADD = '/product-manager/product/coppy/add',

  PRODUCT_GROUP = '/product-manager/product-group',
  PRODUCT_GROUP_ADD = '/product-manager/product-group/add',
  PRODUCT_GROUP_DETAIL = '/product-manager/product-group/edit',
  PRODUCT_CATEGORY = '/product-manager/product-category',
  PRODUCT_BARCODE = '/product-manager/product-barcode',
  PRODUCT_BARCODE_ADD = '/product-manager/product-barcode/add',
  PRODUCT_BARCODE_DETAIL = '/product-manager/product-barcode/detail',

  //Quản lý kho
  WARE_HOUSE_MANAGER = '/warehouse-manager',
  WARE_HOUSE = '/warehouse-manager/warehouse',
  WARE_HOUSE_ADD = '/warehouse-manager/warehouse/add',
  WARE_HOUSE_DETAIL = '/warehouse-manager/warehouse/detail',
  SUPPLIER = '/warehouse-manager/supplier',
  SUPPLIER_ADD = '/warehouse-manager/supplier/add',
  SUPPLIER_DETAIL = '/warehouse-manager/supplier/detail',
  DETAIL_REIPT_OR_PAYSLIP = '/warehouse-manager/supplier/detail/reipt-or-payslip',
  ORDER_FORM = '/warehouse-manager/order-form',
  ORDER_FORM_ADD = '/warehouse-manager/order-form/add',

  ORDER_FORM_DETAIL = '/warehouse-manager/order-form/detail',
  ORDER_FORM_EDIT = '/warehouse-manager/order-form/edit',
  IMPORT_PRODUCTS = '/warehouse-manager/import-products',
  IMPORT_PRODUCTS_ADD = '/warehouse-manager/import-products/add',
  IMPORT_PRODUCTS_EDIT = '/warehouse-manager/import-products/edit',
  IMPORT_PRODUCTS_DETAIL = '/warehouse-manager/import-products/detail',
  IMPORT_PRODUCTS_COPPY = '/warehouse-manager/import-products/coppy',
  CREATE_AND_PRINT_BARCODE = '/warehouse-manager/import-products/create-and-print-barcode',
  RETURN_SUPPLIER = '/warehouse-manager/import-products/return-supplier',

  PAYMENT_FORM = '/warehouse-manager/payment-form',

  TRANSPORT_PRODUCTS = '/warehouse-manager/transport-products',
  TRANSPORT_PRODUCTS_ADD = '/warehouse-manager/transport-products/add',
  TRANSPORT_PRODUCTS_DETAIL = '/warehouse-manager/transport-products/detail',
  TRANSPORT_PRODUCTS_DETAIL_PRINT = '/warehouse-manager/transport-products/detail/print-barcode-trans',
  TRANSPORT_PRODUCTS_EDIT = '/warehouse-manager/transport-products/edit',
  TRANSPORT_PRODUCTS_COPY = '/warehouse-manager/transport-products/copy',

  // quản lý nội dung
  CONTENT_MANAGER = '/content-manager',
  SPLASH = '/content-manager/splash',
  CONTENT_MANAGER_NEWS = '/content-manager/news',
  CONTENT_MANAGER_NEWS_ADD = '/content-manager/news/add',
  CONTENT_MANAGER_NEWS_DETAIL = '/content-manager/news/detail',

  BANNER = '/content-manager/banner',

  ONBOARD = '/content-manager/onboard',

  FAQ = '/content-manager/faq',
  FAQ_DETAIL = '/content-manager/faq/detail',
  FAQ_ADD = '/content-manager/faq/add',
  TERMS_OF_USE = '/content-manager/terms-of-use',
  PRIVACY_POLICY = '/content-manager/privacy-policy',
  // thông báo
  NOTIFICATION = '/notification',
  NOTIFICATION_ADD = '/notification/add',
  NOTIFICATION_UPDATE = '/notification/detail',

  //Địa bàn
  PROVINCE = '/area/province',
  DISTRICT = '/area/district',

  //Quản lý bộ lọc
  SETTING_FILTER = '/setting-filter',
  // cấu hình
  CONFIG = '/config/system',
  TERMS_POLICY = '/config/terms-policy',

  //
  PRICE_MANAGER = '/setting-price',
  PRICE_MANAGER_ADD = '/setting-price/add',
  //Tin tức
  NEWS = '/news',
  NEWS_ADD = '/news/add',
  NEWS_DETAIL = '/news/detail',

  //Khiếu nại
  COMPLAIN_MANAGER = '/complain-manager',
  COMPLAIN_DETAIL = '/complain-detail',
  //Nhóm tài khoản admin
  ADMIN_GROUP = '/admin-group',
  CAR_TYPE = '/car-type',
  CAR_BRAND = 'car-brand',
  BOOKING_FEEDBACK = '/booking-feedback',

  //Danh sách sale
  SALE = '/incentive-manager/sale',
  SALE_ADD = '/incentive-manager/sale/add',
  SALE_DETAIL = '/incentive-manager/sale/detail',

  // Quản lý tặng quà
  GIFT = '/incentive-manager/gift',
  GIFT_ADD = '/incentive-manager/gift/add',
  GIFT_DETAIL = '/incentive-manager/gift/detail',
  //Danh sách mua nhiều giảm nhiều
  BUY_MORE_DISCOUNT_MORE = '/incentive-manager/buy-more-discount-more',
  BUY_MORE_DISCOUNT_MORE_ADD = '/incentive-manager/buy-more-discount-more/add',
  BUY_MORE_DISCOUNT_MORE_DETAIL = '/incentive-manager/buy-more-discount-more/detail',
  // Danh sách Flash sale
  FLASH_SALE = '/incentive-manager/flash-sale',
  FLASH_SALE_ADD = '/incentive-manager/flash-sale/add',
  FLASH_SALE_DETAIL = '/incentive-manager/flash-sale/detail',
  //Loại phiếu
  BILL = '/bill',
  BILL_RECEIPT = '/bill/receipt',
  BILL_PAYSLIP = '/bill/payslip',
  BILL_RECEIPT_DETAIL = '/bill/receipt/detail',
  BILL_PAYSLIP_DETAIL = '/bill/payslip/detail',
  CASH_BOOK = '/bill/cash-book',
  // quản lý voucher
  VOUCHER = '/incentive-manager/voucher',
  VOUCHER_ADD = '/incentive-manager/voucher/add',
  VOUCHER_DETAIL = '/incentive-manager/voucher/detail',
  //Quản lý banner
  BANNER_MANAGER = '/banner-manager/list',
  LIST_ONBOARD = '/banner-manager/onboard-manager',
  //CHAT
  CHAT = '/chat',

  //Báo cáo kho
  REPORT_MANAGER_WAREHOUSE = '/report-manager/warehouse',
  REPORT_MANAGER_WAREHOUSE_INVENTORY = '/report-manager/warehouse/inventory',
  REPORT_MANAGER_WAREHOUSE_INVENTORY_BY_DATE = '/report-manager/warehouse/inventory-by-date',
  REPORT_MANAGER_WAREHOUSE_BOOK = '/report-manager/warehouse/book',
  REPORT_MANAGER_WAREHOUSE_BELOW_NORM = '/report-manager/warehouse/below-norm',
  REPORT_MANAGER_WAREHOUSE_ABOVE_NORM = '/report-manager/warehouse/above-norm',
  REPORT_MANAGER_WAREHOUSE_EXPORT_IMPORT_INVENTORY = '/report-manager/warehouse/inventory-export-import',

  //Báo cáo nhập hàng
  REPORT_MANAGER_IMPORT_PRODUCT = '/report-manager/import-product',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_TIME = '/report-manager/import-product/report-time',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_SUPPLIER = '/report-manager/import-product/report-supplier',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PRODUCT = '/report-manager/import-product/report-product',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_ENTRY_FORM = '/report-manager/import-product/report-entry-form',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_WAREHOUSE = '/report-manager/import-product/report-warehouse',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PAYMENT_BY_TIME = '/report-manager/import-product/report-payment-by-time',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PAYMENT_BY_EMPOLYEE = '/report-manager/import-product/report-payment-by-employee',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PAYMENT_BY_WAREHOUSE = '/report-manager/import-product/report-payment-by-warehouse',

  //Báo cáo tài chính

  REPORT_MANAGER_FINANCE = '/report-manager/report-finance',
  REPORT_MANAGER_FINANCE_PROFIT_LOSS = '/report-manager/report-finance/profit-and-loss',
  REPORT_MANAGER_FINANCE_DEBT_CUSTOMER = '/report-manager/report-finance/debt-customer',
  REPORT_MANAGER_FINANCE_DEBT_SUPPLIER = '/report-manager/report-finance/debt-supplier',

  //Báo cáo khách hàng
  REPORT_MANAGER_CUSTOMER = '/report-manager/import-product/report-customer',

  // lịch sử excel
  EXCEL_HISTORY = '/excel-history',

  //Báo cáo bán hàng
  REPORT_MANAGER_SALES = '/report-manager/sales',
  REPORT_MANAGER_SALES_BY_TIME = '/report-manager/sales/by-time',
  REPORT_MANAGER_SALES_BY_EMPLOYEE = '/report-manager/sales/by-employee',
  REPORT_MANAGER_SALES_BY_PRODUCT = '/report-manager/sales/by-product',
  REPORT_MANAGER_SALES_BY_ORDER = '/report-manager/sales/by-order',
  REPORT_MANAGER_SALES_BY_WAREHOUSE = '/report-manager/sales/by-warehouse',
  REPORT_MANAGER_SALES_BY_SOURCE = '/report-manager/sales/by-source',
  REPORT_MANAGER_SALES_BY_CUSTOMER = '/report-manager/sales/by-customer',
  REPORT_MANAGER_DELIVERY_BY_TIME = '/report-manager/delivery-by-time',
  REPORT_MANAGER_DELIVERY_BY_EMPLOYEE = '/report-manager/delivery-by-employee',
  REPORT_MANAGER_DELIVERY_BY_DELIVERY_FORM = '/report-manager/delivery-by-delivery-form',
  REPORT_MANAGER_DELIVERY_BY_PARTNER = '/report-manager/delivery-by-partner',
  REPORT_MANAGER_DELIVERY_BY_WAREHOUSE = '/report-manager/delivery-by-warehouse',
  REPORT_MANAGER_DELIVERY_BY_CUSTOMER = '/report-manager/delivery-by-customer',
  REPORT_MANAGER_DELIVERY_BY_SOURCE = '/report-manager/delivery-by-source',
  REPORT_MANAGER_RETURN_PRODUCT_BY_ORDER = '/report-manager/sales/return-product-by-order',
  REPORT_MANAGER_RETURN_PRODUCT_BY_PRODUCT = '/report-manager/sales/return-product-by-product',
  REPORT_MANAGER_STATISTICAL_BY_ORDER = '/report-manager/sales/statistical-by-order',
  REPORT_MANAGER_STATISTICAL_BY_PRODUCT = '/report-manager/sales/statistical-by-product',

  REPORT_MANAGER_PAYMENT_BY_TIME = '/report-manager/sales/payment-by-time',
  REPORT_MANAGER_PAYMENT_BY_EMPLOYEE = '/report-manager/sales/payment-by-employee',
  REPORT_MANAGER_PAYMENT_BY_WAREHOUSE = '/report-manager/sales/payment-by-warehouse',

  //Quản lý tin Fake
  FAKE_NEWS = '/fake-news',
}

export enum EBreadCrumdText {
  HOMEAPGE = 'Trang chủ',
  // Quản lý tài khoản
  ACCOUNT_CUSTOMER = 'Quản lý tài khoản/Tài khoản khách hàng',
  ACCOUNT_CUSTOMER_DETAIL = 'Quản lý tài khoản/Thông tin chi tiết tài khoản',
  ACCOUNT_CUSTOMER_BILL = 'Chi tiết phiếu thu',

  ACCOUNT_ADMIN = 'Quản lý tài khoản/Tài khoản admin',
  // Quản lý hạng tài khoản
  ACCOUNT_RANK = 'Quản lý hạng tài khoản',
  ACCOUNT_RANK_LIST = 'Quản lý tài khoản/Danh sách hạng',
  ACCOUNT_GROUP_ADMIN = 'Quản lý tài khoản/Quản lý nhóm tài khoản Admin',
  ACCOUNT_LIST_REGISTER = 'Quản lý tài khoản/Tài khoản đăng ký nhiều vai trò',
  //Quản lý đơn hàng
  ORDER_MANAGER = 'Quản lý đơn hàng',
  ORDER_MANAGER_LIST = 'Quản lý đơn hàng/Danh sách đơn hàng',
  ORDER_MANAGER_ADD = 'Quản lý đơn hàng/Thêm mới đơn hàng',
  ORDER_MANAGER_DETAIL = 'Quản lý đơn hàng/Chi tiết đơn hàng',
  ORDER_MANAGER_EDIT = 'Quản lý đơn hàng/Sửa đơn hàng',

  CUSTOMER_RETURN_PRODUCT = 'Quản lý đơn hàng/Khách trả hàng',
  CUSTOMER_RETURN_PRODUCT_ADD = 'Quản lý đơn hàng/Tạo mới đơn trả hàng',
  CUSTOMER_RETURN_PRODUCT_DETAIL = 'Quản lý đơn hàng/Chi tiết đơn trả hàng',
  //Quản lý sản phẩm
  PRODUCT = 'Quản lý sản phẩm/Danh sách sản phẩm',
  PRODUCT_ADD = 'Quản lý sản phẩm/Thêm mới sản phẩm',
  PRODUCT_DETAIL = 'Quản lý sản phẩm/Chi tiết sản phẩm',
  PRODUCT_DETAIL_EDIT = 'Quản lý sản phẩm/Sửa sản phẩm',
  PRODUCT_COPPY_ADD = 'Quản lý sản phẩm/Thêm mới sản phẩm',
  PRODUCT_CATEGORY = 'Quản lý sản phẩm/Danh mục sản phẩm',
  PRODUCT_GROUP = 'Quản lý sản phẩm/Nhóm sản phẩm',
  PRODUCT_GROUP_ADD = 'Quản lý sản phẩm/Thêm mới nhóm sản phẩm',
  PRODUCT_GROUP_DETAIL = 'Quản lý sản phẩm/Chi tiết nhóm sản phẩm',
  PRODUCT_BARCODE = 'Quản lý sản phẩm/Danh sách barcode sản phẩm',
  PRODUCT_BARCODE_ADD = 'Quản lý sản phẩm/Thêm mới barcode sản phẩm',
  PRODUCT_BARCODE_DETAIL = 'Quản lý sản phẩm/Chi tiết barcode sản phẩm',
  //Quản lý kho
  WARE_HOUSE_MANAGER = 'Quản lý kho',
  WARE_HOUSE = 'Quản lý kho/Danh sách kho',
  WARE_HOUSE_ADD = 'Quản lý kho/Thêm mới kho',
  WARE_HOUSE_DETAIL = 'Quản lý kho/Chi tiết kho',
  //nhà cung cấp
  SUPPLIER = 'Quản lý kho/Danh sách nhà cung cấp',
  SUPPLIER_ADD = 'Quản lý kho/Thêm mới nhà cung cấp',
  SUPPLIER_DETAIL = 'Quản lý kho/Chi tiết nhà cung cấp',
  DETAIL_REIPT_OR_PAYSLIP = 'Quản lý kho/Chi tiết nhà cung cấp/Chi tiết phiếu thu chi',
  // đơn đặt hàng
  ORDER_FORM = 'Quản lý kho/Danh sách đơn đặt hàng',
  ORDER_FORM_ADD = 'Quản lý kho/Thêm mới đơn đặt hàng',
  ORDER_FORM_DETAIL = 'Nhập hàng/Chi tiết đơn đặt',
  ORDER_FORM_EDIT = 'Nhập hàng/Sửa đơn đặt',
  //Nhập hàng
  IMPORT_PRODUCTS = 'Quản lý kho/Danh sách nhập hàng',
  IMPORT_PRODUCTS_ADD = 'Quản lý kho/Thêm mới nhập hàng',
  IMPORT_PRODUCTS_EDIT = 'Quản lý kho/Sửa đơn nhập hàng',
  IMPORT_PRODUCTS_DETAIL = 'Quản lý kho/Chi tiết nhập hàng',
  IMPORT_PRODUCTS_COPPY = 'Quản lý kho/Chi tiết nhập hàng/Sao chép nhập hàng',
  CREATE_AND_PRINT_BARCODE = 'Nhập hàng/ In mã vạch',
  RETURN_SUPPLIER = 'Nhập hàng/Chi tiết đơn nhập/Hoàn hàng',

  PAYMENT_FORM = 'Quản lý kho/Danh sách đơn trả nhà cung cấp',

  TRANSPORT_PRODUCTS = 'Quản lý kho/Danh sách điều chuyển',
  TRANSPORT_PRODUCTS_ADD = 'Quản lý kho/Thêm mới điều chuyển',
  TRANSPORT_PRODUCTS_DETAIL = 'Quản lý kho/Chi tiết điều chuyển',
  TRANSPORT_PRODUCTS_DETAIL_PRINT = 'Quản lý kho/Điều chuyển/In mã vạch',
  TRANSPORT_PRODUCTS_EDIT = 'Quản lý kho/Sửa điều chuyển',
  TRANSPORT_PRODUCTS_COPY = 'Quản lý kho/Sao chép đơn điều chuyển',
  // quản lý voucher
  VOUCHER = 'Quản lý khuyến mại',
  VOUCHER_DETAIL = 'Quản lý khuyến mại/Thông tin chi tiết khuyến mại',
  VOUCHER_ADD = 'Quản lý khuyến mại/Thêm mới khuyến mại',

  // thông báo
  NOTIFICATION = 'Quản lý thông báo',
  NOTIFICATION_ADD = 'Quản lý thông báo/Thêm mới thông báo',
  NOTIFICATION_UPDATE = 'Quản lý thông báo/Cập nhật thông báo',
  // quản lý nội dung
  CONTENT_MANAGER = 'Quản lý nội dung',
  CONTENT_MANAGER_RULES_AND_REGULATION = 'Quản lý nội dung/Nội quy và quy chế',
  CONTENT_MANAGER_NEWS = 'Quản lý nội dung/Danh sách tin tức',
  CONTENT_MANAGER_NEWS_ADD = 'Quản lý nội dung/Thêm mới tin tức',
  CONTENT_MANAGER_NEWS_DETAIL = 'Quản lý nội dung/Chi tiết tin tức',
  SPLASH = 'Quản lý nội dung/Quản lý splash',
  BANNER = 'Quản lý nội dung/Banner',
  ONBOARD = 'Quản lý nội dung/Onboard',
  RULES_AND_REGULATIONS = 'Quản lý nội dung/Nội quy và quy chế',
  FAQ = 'Quản lý nội dung/FAQ',
  FAQ_DETAIL = 'Quản lý nội dung/Chi tiết FAQ',
  FAQ_ADD = 'Quản lý nội dung/Thêm mới FAQ',
  TERMS_OF_USE = 'Quản lý nội dung/Điều khoản sử dụng',
  PRIVACY_POLICY = 'Quản lý nội dung/Điều khoản chính sách',

  //Địa bàn
  PROVINCE = 'Quản lý địa bàn/Quản lý Tỉnh thành',
  DISTRICT = 'Quản lý địa bàn/Quản lý Quận huyện',

  //Quản lý bộ lọc

  // cấu hình
  CONFIG = 'Cấu hình',
  TERMS_POLICY = 'Cấu hình/Điều khoản và chính sách',

  //Tin tức
  NEWS = 'Tin Tức',
  NEWS_ADD = 'Tin tức/Thêm mới tin tức',
  NEWS_DETAIL = 'Tin tức/Chi tiết tin tức',
  NEWS_EDIT = 'Tin tức/Chỉnh sửa tin tức',

  //Danh sách sale
  SALE = 'Quản lý chương trình sale',
  SALE_DETAIL = 'Quản lý chương trình sale/Chi tiết chương trình sale',
  SALE_ADD = 'Quản lý chương trình sale/Thêm mới chương trình sale',
  // Quản lý tặng quà
  GIFT = 'Tặng quà',
  GIFT_ADD = 'Tặng quà/Thêm mới tặng quà',
  GIFT_DETAIL = 'Tặng quà/Chi tiết tặng quà',
  //Danh sách mua nhiều giảm nhiều
  BUY_MORE_DISCOUNT_MORE = 'Danh sách sản phẩm mua nhiều giảm nhiều',
  BUY_MORE_DISCOUNT_MORE_ADD = 'Danh sách sản phẩm mua nhiều giảm nhiều/Thêm mới',
  BUY_MORE_DISCOUNT_MORE_DETAIL = 'Danh sách sản phẩm mua nhiều giảm nhiều/Chi tiết',
  // Danh sách Flash sale
  FLASH_SALE = 'Danh sách Flash sale',
  FLASH_SALE_DETAIL = 'Danh sách Flash sale/Chi tiết Flash sale',
  FLASH_SALE_ADD = 'Danh sách Flash sale/Thêm mới Flash sale',

  //Loại phiếu
  BILL = 'Loại phiếu',
  BILL_RECEIPT = 'Danh sách phiếu thu',
  BILL_PAYSLIP = 'Danh sách phiếu chi',
  BILL_PAYSLIP_DETAIL = 'Chi tiết phiếu chi',
  BILL_RECEIPT_DETAIL = 'Chi tiết phiếu thu',
  CASH_BOOK = 'Sổ quỹ',
  //Quản lý banner
  BANNER_MANAGER = 'Quản lý banner/Danh sách banner',
  LIST_ONBOARD = 'Quản lý banner/Danh sách màn onboard',
  //Khiếu nại
  COMPLAIN_MANAGER = 'Khiếu nại,kiến nghị',
  //Chat
  CHAT = 'Chat',

  // Báo cáo kho
  REPORT_MANAGER_WAREHOUSE = 'Báo cáo kho',
  REPORT_MANAGER_WAREHOUSE_INVENTORY = 'Báo cáo kho/Báo cáo tồn kho',
  REPORT_MANAGER_WAREHOUSE_INVENTORY_BY_DATE = 'Báo cáo kho/Báo cáo tồn kho theo date',
  REPORT_MANAGER_WAREHOUSE_BOOK = 'Báo cáo kho/Sổ kho',
  REPORT_MANAGER_WAREHOUSE_BELOW_NORM = 'Báo cáo kho/Báo cáo tồn kho dưới định mức',
  REPORT_MANAGER_WAREHOUSE_ABOVE_NORM = 'Báo cáo kho/Báo cáo tồn kho trên định mức',
  REPORT_MANAGER_WAREHOUSE_EXPORT_IMPORT_INVENTORY = 'Báo cáo kho/Báo cáo xuất nhập tồn',
  //Báo cáo Nhập hàng
  REPORT_MANAGER_IMPORT_PRODUCT = 'Báo cáo nhập hàng',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_TIME = 'Báo cáo nhập hàng / Báo cáo theo thời gian',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_SUPPLIER = 'Báo cáo nhập hàng / Báo cáo theo nhà cung cấp',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PRODUCT = 'Báo cáo nhập hàng / Báo cáo theo sản phẩm',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_ENTRY_FORM = 'Báo cáo nhập hàng / Báo cáo theo đơn nhập',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_WAREHOUSE = 'Báo cáo nhập hàng / Báo cáo nhập hàng theo kho',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PAYMENT_BY_TIME = 'Báo cáo nhập hàng / Thanh toán theo thời gian',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PAYMENT_BY_EMPOLYEE = 'Báo cáo nhập hàng / Thanh toán theo nhân viên',
  REPORT_MANAGER_IMPORT_PRODUCT_REPORT_PAYMENT_BY_WAREHOUSE = 'Báo cáo nhập hàng / Thanh toán theo chi nhánh',

  //Báo cáo tài chính
  REPORT_MANAGER_FINANCE = 'Báo cáo tài chính',
  REPORT_MANAGER_FINANCE_PROFIT_LOSS = 'Báo cáo tài chính/Lãi lỗ',
  REPORT_MANAGER_FINANCE_DEBT_CUSTOMER = 'Báo cáo tài chính/Công nợ khách hàng',
  REPORT_MANAGER_FINANCE_DEBT_SUPPLIER = 'Báo cáo tài chính/Công nợ nhà cung cấp',
  //Báo cáo khách hàng
  REPORT_MANAGER_CUSTOMER = 'Báo cáo khách hàng',

  // lịch sử excel
  EXCEL_HISTORY = 'Lịch sử Excel ',

  //Báo cáo bán hàng
  REPORT_MANAGER_SALES = 'Báo cáo bán hàng',
  REPORT_MANAGER_SALES_BY_TIME = 'Báo cáo bán hàng/Doanh thu cửa hàng theo thời gian',
  REPORT_MANAGER_SALES_BY_EMPLOYEE = 'Báo cáo bán hàng/Doanh thu cửa hàng theo nhân viên',
  REPORT_MANAGER_SALES_BY_PRODUCT = 'Báo cáo bán hàng/Doanh thu cửa hàng theo sản phẩm',
  REPORT_MANAGER_SALES_BY_ORDER = 'Báo cáo bán hàng/Doanh thu cửa hàng theo đơn hàng',
  REPORT_MANAGER_SALES_BY_WAREHOUSE = 'Báo cáo bán hàng/Doanh thu cửa hàng theo chi nhánh',
  REPORT_MANAGER_SALES_BY_SOURCE = 'Báo cáo bán hàng/Doanh thu cửa hàng theo nguồn đơn',
  REPORT_MANAGER_SALES_BY_CUSTOMER = 'Báo cáo bán hàng/Doanh thu cửa hàng theo khách hàng',
  REPORT_MANAGER_RETURN_PRODUCT_BY_ORDER = 'Báo cáo bán hàng/Trả hàng theo đơn hàng',
  REPORT_MANAGER_RETURN_PRODUCT_BY_PRODUCT = 'Báo cáo bán hàng/Trả hàng theo sản phẩm',
  REPORT_MANAGER_STATISTICAL_BY_ORDER = 'Báo cáo bán hàng/Báo cáo thống kê theo đơn hàng',
  REPORT_MANAGER_STATISTICAL_BY_PRODUCT = 'Báo cáo bán hàng/Báo cáo thống kê theo sản phẩm',
  REPORT_MANAGER_PAYMENT_BY_TIME = 'Báo cáo bán hàng/Báo cáo thanh toán theo thời gian',
  REPORT_MANAGER_PAYMENT_BY_EMPLOYEE = 'Báo cáo bán hàng/Báo cáo thanh toán theo nhân viên',
  REPORT_MANAGER_PAYMENT_BY_WAREHOUSE = 'Báo cáo bán hàng/Báo cáo thanh toán theo chi nhánh',

  //Báo cáo giao hàng
  REPORT_MANAGER_DELIVERY_BY_TIME = 'Báo cáo bán hàng/Báo cáo giao hàng theo thời gian',
  REPORT_MANAGER_DELIVERY_BY_EMPLOYEE = 'Báo cáo bán hàng/Báo cáo giao hàng theo nhân viên',
  REPORT_MANAGER_DELIVERY_BY_DELIVERY_FORM = 'Báo cáo bán hàng/Báo cáo giao hàng theo vận đơn,phiếu giao hàng',
  REPORT_MANAGER_DELIVERY_BY_PARTNER = 'Báo cáo bán hàng/Báo cáo giao hàng theo đối tác vận chuyển',
  REPORT_MANAGER_DELIVERY_BY_WAREHOUSE = 'Báo cáo bán hàng/Báo cáo giao hàng theo chi nhánh',
  REPORT_MANAGER_DELIVERY_BY_CUSTOMER = 'Báo cáo bán hàng/Báo cáo giao hàng theo khách hàng',
  REPORT_MANAGER_DELIVERY_BY_SOURCE = 'Báo cáo bán hàng/Báo cáo giao hàng theo nguồn đơn hàng',

  //Quản lý tin Fake
  FAKE_NEWS = 'Quản lý tin fake',
}
